import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Company } from '../../contracts/company'
import { classNames } from '../../utility/class-names'

export interface CompanyCardProps {
  key?: string
  className?: string
  company: Pick<Company, 'id' | 'name' | 'image'>
  onClick?: () => void
}

export default ({ company, className, onClick, ...rest }: CompanyCardProps) => {
  const image = getImage(company.image)

  return (
    <div
      className={classNames(
        className,
        'card text-gray-700',
      )}
      onClick={onClick}
      {...rest}
    >
      {image && 
        <GatsbyImage
          image={image}
          alt={company.name}
          objectFit='contain'
          className="w-40 h-30 mb-4 bg-white border-4 border-white"
        />
      }
      <span className='mt-2'>{company.name}</span>
    </div>
  )
}