import { graphql, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import CompanyCard from '../../components/company-card/company-card'
import { Company } from '../../contracts/company'
import { getMembership, saveMembership } from '../../services/memberships'
import { useAuthStore } from '../../stores/auth-store'
import { useMembershipStore } from '../../stores/membership-store'
import { classNames } from '../../utility/class-names'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import Loading from '../../components/loading/loading'
import { listOffersForMembership } from '../../services/offer'
import Modal from '../../components/modal/modal'
import Login from '../../components/login/login'

interface MembershipsChooseProps {
  data: {
    allCompany: any
  }
}

export default (props: MembershipsChooseProps) => {
  const companies = (props.data.allCompany.edges as any[])
    .map<Company>((edge: any) => edge.node)
    .filter(company => company.availableOffers.length > 0)

  const user = useAuthStore(state => state.user)
  const isUserLoaded = useAuthStore(state => state.isLoaded)
  const membership = useMembershipStore(state => state.membership)
  const setMembership = useMembershipStore(state => state.setMembership)
  const setMembershipOffers = useMembershipStore(state => state.setMembershipOffers)

  const [showSignInModal, setShowSignInModal] = useState(false)

  useEffect(() => {
    if (!isUserLoaded) {
      return
    }
    const fetch = async () => {
      if (!user) {
        setMembership({ companyIds: [] })
      } else {
        const membership = await getMembership(user.uid)
        const companyIds = membership?.companyIds || []
        setMembership({ companyIds })
      }
    }
    fetch()
  }, [user, isUserLoaded])

  const toggleCompany = (company: Company) => {
    if (membership?.companyIds.includes(company.id)) {
      // Remove
      const companyIds = membership?.companyIds.filter(companyId => companyId !== company.id)
      setMembership({ companyIds })
    } else {
      // Add
      const companyIds = [...membership?.companyIds ?? [], company.id]
      setMembership({ companyIds })
    }
  }

  const handleSave = async () => {
    if (isUserLoaded && user) {
      return continueSave()
    } else {
      if (membership) {
        await listOffersForMembership(membership.companyIds).then(setMembershipOffers)
      }
      setShowSignInModal(true)
    } 
  }

  const continueSave = async () => {
    if (user && membership) {
      await Promise.all([
        listOffersForMembership(membership.companyIds).then(setMembershipOffers),
        saveMembership(user.uid, membership.companyIds)
      ])
    }
    navigate('/memberships')
  }

  const handleContinueWithoutSigningIn = async () => {
    navigate('/memberships')
  }

  return (
    <main className='pt-4 container'>
      {showSignInModal &&
        <Modal>
          <div>
            <div className="mt-3 sm:mt-5">
              <h3 className="text-lg text-center leading-6 font-medium text-gray-900">
                Your discounts are just one click away!
              </h3>
              <div className="mt-4">
                <p className="text-sm text-gray-500 mb-6" >
                  Sign in now to store your membership and offer settings.
                </p>
                <Login onLoggedIn={continueSave} />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="button button-outline shadow-none w-full"
              onClick={() => handleContinueWithoutSigningIn()}
            >
              Continue without signing in
            </button>
          </div>
        </Modal>
      }

      <h1 className='text-2xl text-center mt-4 mb-8'>
        Choose which memberships you hold, and we'll find all offers that you're eligible for.
      </h1>

      <div className='relative'>
        {!membership && 
          <div className='absolute w-full h-full backdrop-blur pt-16 flex items-start justify-center z-10'>
            <Loading />
          </div>
        }

        <section className='mt-4 grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-[220px]'>
          {companies.map(company => {
            const isSelected = membership?.companyIds.includes(company.id)

            return (
              <CompanyCard
                key={company.id}
                company={company}
                className={
                  classNames(
                    'm-2 cursor-pointer',
                    isSelected ? 'card-link card-selected' : ''
                  )
                }
                onClick={() => toggleCompany(company)}
              />
            )
          })
        }
        </section>
      </div>
      
      <div className='mt-10 mb-6 text-center fixed bottom-0 right-2 lg:relative'>
        <button
          className='button px-8 py-4 text-lg'
          onClick={handleSave}
        >
          Show My Offers
          <ArrowRightIcon className='ml-2 w-4 inline-block' />
        </button>
      </div>
    </main>
  )
}

export const query = graphql`
query {
  allCompany {
    edges {
      node {
        id
        name
        availableOffers {
          id
        }
        image {
          childImageSharp {
            gatsbyImageData(width: 320)
          }
        }
      }
    }
  }
}
`